import { useAuth } from '@clerk/clerk-react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { deleteResolve, fetchResolve } from './helpers'
import { useToast } from '@/hooks/use-toast'
import { createServerErrorToast } from '@/lib/toast'

import { FeedbackStats } from '@/types/FeedbackStats'
import { FeedbackPoint } from '@/types/FeedbackPoint'

export const useFetchFeedbackPoints = () => {
  const { getToken } = useAuth()
  return useQuery<FeedbackPoint[]>({
    queryKey: ['useFetchFeedbackPoints'],
    queryFn: () => fetchResolve(`/feedback/point`, getToken),
  })
}

export const useFetchFeedbackStats = () => {
  const { getToken } = useAuth()
  return useQuery<FeedbackStats>({
    queryKey: ['useFetchFeedbackStats'],
    queryFn: () => fetchResolve(`/feedback/stats`, getToken),
    initialData: {
      total: 0,
      done: {
        meets_checklist: 0,
        does_not_meet_checklist: 0,
      },
      missedProactiveFeedback: 0,
      checklistCompliance: 0,
      weekDetails: [],
      companyValuesAverage: {},
      companyValuesKeys: [],
    },
  })
}

export const useDeleteFeedbackPoint = () => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async ({ id }: { id: string }) =>
      await deleteResolve(`/feedback/point/${id}`, getToken),
    onSuccess: (_, variables) => {
      queryClient.setQueryData(
        ['useFetchFeedbackPoints'],
        (oldData: FeedbackPoint[]) =>
          oldData && oldData.filter((fp) => fp.id !== variables.id),
      )
    },
    onError: (error) => {
      toast(createServerErrorToast(error.message))
    },
  })
}
