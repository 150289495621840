import { useCallback, useMemo, useState } from 'react'
import moment from 'moment'
import { ExternalLink } from 'lucide-react'

import { DataTable } from '@/components/ui/data-table'
import { H4, P } from '@/components/ui/typography'
import { Loading } from '@/components/ui/loading'
import { Button } from '@/components/ui/button'
import { NewObjectiveModal } from '@/components/objective-modal'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { LayoutTile } from '@/components/layout'

import {
  useDeleteObjective,
  useFetchTeamObjectives,
  usePatchObjective,
  usePatchObjectiveValue,
} from '@/services/api/objective.api'

import { generateColumns, generateRows } from './objectives.config'
import {
  Quarter,
  getIntervalsFromQuarter,
  getQuarterFromDate,
  parseQuarterString,
} from '@/services/utils/dates'
import { TypeEditInfo } from '@inovua/reactdatagrid-community/types'
import { Objective } from '@/types/Objective'
import { UserProfile } from '@/types/UserProfile'

export const ObjectivesPage = () => {
  const [addNewFormOpen, setAddNewFormOpen] = useState(false)
  const [selectedQuarter, setSelectedQuarter] = useState<{
    label: string
    value: Quarter
  }>(getQuarterFromDate(moment().format()))

  const quarterIntervals = useMemo(
    () => getIntervalsFromQuarter(selectedQuarter.value),
    [selectedQuarter],
  )

  const { data: objectives = [], isLoading: isLoadingObjectives } =
    useFetchTeamObjectives(selectedQuarter.value)
  const { mutate: deleteObjective } = useDeleteObjective(selectedQuarter.value)
  const { mutate: patchObjective } = usePatchObjective(selectedQuarter.value)
  const { mutate: patchObjectiveValue } = usePatchObjectiveValue(
    selectedQuarter.value,
  )

  const handleNewObjective = () => {
    setAddNewFormOpen(true)
  }

  const handleDeleteClick = useCallback(
    (objective: Objective) => {
      deleteObjective(objective.id)
    },
    [deleteObjective],
  )

  const handleEdit = (editInfo: TypeEditInfo) => {
    const { rowId, columnId, value, data } = editInfo as TypeEditInfo & {
      data: Objective
    }

    if (data[columnId as keyof Objective] === value) {
      return
    }

    // For the weekly values, we need to apply some extra logic
    // in order to update the record correctly
    if (columnId.includes('week')) {
      const weekNumber = Number(columnId.split('-')?.[1])
      const newObjectiveItem = {
        ...data.values.find((item) => weekNumber === item.weekNumber)!,
        value,
      }

      return patchObjectiveValue({
        teamObjectiveId: rowId,
        body: newObjectiveItem,
      })
    }

    patchObjective({
      id: rowId,
      body: {
        [columnId as keyof Objective]: value,
      },
    })
  }

  const handlePatchOwners = useCallback(
    (id: string, newOwners: UserProfile[]) => {
      patchObjective({
        id,
        body: {
          owners: newOwners,
        },
      })
    },
    [patchObjective],
  )

  const columns = useMemo(
    () =>
      generateColumns(quarterIntervals, handleDeleteClick, handlePatchOwners),
    [quarterIntervals, handleDeleteClick, handlePatchOwners],
  )
  const rows = useMemo(() => generateRows(objectives), [objectives])
  return (
    <LayoutTile>
      <div>
        <H4 className="font-semibold">Objectives</H4>
        <div className="flex flex-col sm:flex-row sm:justify-between mt-2">
          <P className="text-muted-foreground">
            Track and update your (or your team&apos;s) objectives.{' '}
            <a
              href="https://fidforward.canny.io/changelog/objectives-is-now-available"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary hover:underline inline-flex items-center"
            >
              Learn more
              <ExternalLink size={16} className="ml-1" />
            </a>
          </P>
          <div className="flex items-center gap-4">
            <Select
              value={selectedQuarter.label}
              onValueChange={(value) => {
                setSelectedQuarter(parseQuarterString(value))
              }}
            >
              <SelectTrigger className="w-[130px]">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="2024 Q1">2024 Q1</SelectItem>
                <SelectItem value="2024 Q2">2024 Q2</SelectItem>
                <SelectItem value="2024 Q3">2024 Q3</SelectItem>
                <SelectItem value="2024 Q4">2024 Q4</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>
      <Button onClick={handleNewObjective} className="w-fit">
        Add New Objective
      </Button>
      {isLoadingObjectives ? (
        <Loading containerClassName="w-full h-full flex justify-center items-center" />
      ) : (
        <DataTable columns={columns} data={rows} onEditComplete={handleEdit} />
      )}
      <NewObjectiveModal
        isOpen={addNewFormOpen}
        setIsOpen={setAddNewFormOpen}
        intervals={quarterIntervals}
        selectedQuarter={selectedQuarter.value}
      />
    </LayoutTile>
  )
}
