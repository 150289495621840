import { useAuth } from '@clerk/clerk-react'
import { addQueryParams, fetchResolve } from './helpers'
import {
  FeedbackSession,
  FeedbackSessionOverview,
} from '@/types/FeedbackSessions'
import { useQuery } from '@tanstack/react-query'

import { UseQueryOptions } from '@/types/globals'

export const useFetchFeedbackSessions = (
  dateRange:
    | {
        from?: number
        to?: number
      }
    | undefined,
) => {
  const { getToken } = useAuth()
  return useQuery<FeedbackSessionOverview[]>({
    queryKey: ['useFetchFeedbackSessions', dateRange],
    queryFn: () =>
      fetchResolve(
        addQueryParams(`/feedback/session`, {
          from: dateRange!.from,
          to: dateRange!.to,
        }),
        getToken,
      ),
    enabled: !!dateRange,
  })
}

export const useFetchFeedbackSession = (
  userId: string,
  dateRange: { from: number; to: number },
  includeAllFilter: boolean,
  options?: UseQueryOptions,
) => {
  const { getToken } = useAuth()
  return useQuery<FeedbackSession | undefined>({
    queryKey: [
      'useFetchFeedbackSession',
      userId,
      dateRange.from,
      dateRange.to,
      includeAllFilter,
    ],
    queryFn: () =>
      fetchResolve(
        addQueryParams(`/feedback/session/user/${userId}`, {
          from: dateRange.from,
          to: dateRange.to,
          includeAll: includeAllFilter,
        }),
        getToken,
      ),
    staleTime: 15 * 60 * 1000, // 15 Minutes - Heavy operation so we should not trigger refetches
    ...options,
  })
}
