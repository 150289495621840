import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import posthog from 'posthog-js'
import * as Sentry from '@sentry/react'
import { Toaster } from '@/components/ui/toaster'

import ReactQueryClientProvider from '@/ctxs/ReactQueryClientProvider'
import {
  MOCK_SERVER_ENABLED,
  POSTHOG_PUBLISHABLE_KEY,
  POSTHOG_API_HOST,
  SENTRY_DNS,
  IS_PRODUCTION,
  IS_DEV,
} from '@/services/config/env'
import { router } from '@/router'
import '@/index.css'

// Initialize PostHog
if (!IS_DEV) {
  posthog.init(POSTHOG_PUBLISHABLE_KEY, {
    api_host: POSTHOG_API_HOST,
    person_profiles: 'always',
  })
}

// Sentry config
if (IS_PRODUCTION) {
  Sentry.init({
    dsn: SENTRY_DNS,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
  })
}

async function enableMocking() {
  if (!MOCK_SERVER_ENABLED) {
    return
  }

  const { worker } = await import('@/services/mocks/browser')

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start()
}

enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <ReactQueryClientProvider>
        <RouterProvider router={router} />
        <Toaster />
      </ReactQueryClientProvider>
    </React.StrictMode>,
  )
})
