import React, { useMemo } from 'react'
import moment from 'moment'
import { useUser } from '@clerk/clerk-react'
import lodash from 'lodash'
import colors from 'tailwindcss/colors'

import BarChart from '@/components/common/bar-chart'
import LineChart, { LINE_CHART_COLORS } from '@/components/common/line-chart'
import { Tile } from '@/components/common/tile'
import { Muted, H4, H2 } from '@/components/ui/typography'
import { useFetchFeedbackStats } from '@/services/api/feedbackPoints.api'
import {
  formatDecimalToPercentage,
  getUserDisplayName,
} from '@/services/utils/formatters'

import pastWeekIntervals from '@/services/utils/pastWeekIntervals'
import { average } from '@/services/utils/math'
import { useScreenDetector } from '@/hooks/useScreenDetector'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { Icon } from '@/components/ui/icon'
import { Link } from 'react-router-dom'
import { Progress } from '@/components/ui/progress'
import { TooltipWithIcon } from '@/components/common/tooltip'
import { LayoutTile } from '@/components/layout'

export const Dashboard = () => {
  const { user } = useUser()
  const { data: feedbackStats, isLoading } = useFetchFeedbackStats()
  const { isMobile } = useScreenDetector()
  const pastWeekIntervalsToShow = useMemo(
    () => (isMobile ? pastWeekIntervals.slice(3) : pastWeekIntervals),
    [isMobile],
  )
  const hasGoogleConnected = useMemo(
    () =>
      !user?.externalAccounts?.find((acc) => acc.provider === 'google')
        ?.emailAddress,
    [user],
  )

  const checklistComplianceValue = useMemo(() => {
    return feedbackStats?.checklistCompliance ?? 0
  }, [feedbackStats])

  const companyValues = useMemo(() => {
    if (!feedbackStats?.companyValuesAverage) return []
    return Object.entries(feedbackStats.companyValuesAverage).map(
      ([name, score]) => ({
        name,
        score,
      }),
    )
  }, [feedbackStats?.companyValuesAverage])

  const sortedCompanyValues = useMemo(() => {
    return [...companyValues].sort((a, b) => b.score - a.score)
  }, [companyValues])

  return (
    <LayoutTile className="bg-transparent p-0 sm:p-0">
      {hasGoogleConnected && (
        <Link target="_blank" to="/account">
          <Alert className="mb-4">
            <Icon name="CalendarFold" className="h-4 w-4 text-green-600" />
            <AlertTitle className="text-green-600">
              Fiddy is waiting for you!
            </AlertTitle>
            <AlertDescription>
              <Muted>
                Our intelligent robot Fiddy needs access to your Calendar to
                bring you the best experience. Click&nbsp;<b>here</b>&nbsp;to do
                it.
              </Muted>
            </AlertDescription>
          </Alert>
        </Link>
      )}
      <div>
        <H2>
          Welcome to FidForward{user ? `, ${getUserDisplayName(user)}!` : '!'}
        </H2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <MetricTile
          title="Feedback Points"
          value={
            feedbackStats.done.meets_checklist +
            feedbackStats.done.does_not_meet_checklist
          }
          isLoading={isLoading}
          tooltipContent="Number of Feedback Points with a clear target"
        />
        <MetricTile
          title="Missed Feedback"
          value={formatDecimalToPercentage(
            feedbackStats.missedProactiveFeedback,
            0,
          )}
          isLoading={isLoading}
          valueColor={
            feedbackStats.missedProactiveFeedback > 0.3
              ? 'text-rose-400'
              : 'text-green-600'
          }
          tooltipContent="Percentage of nudges from Fiddy you've missed"
          tooltipType="info"
        />
        <MetricTile
          title="Quality score"
          value={formatDecimalToPercentage(checklistComplianceValue, 0)}
          isLoading={isLoading}
          valueColor={
            checklistComplianceValue < 0.7 ? 'text-rose-400' : 'text-green-600'
          }
          tooltipContent="Percentage of Feedback Points that meet the quality threshold"
          tooltipType="info"
        />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-5 gap-4">
        <Tile className="lg:col-span-3 h-[300px]" isLoading={isLoading}>
          <div>
            <Muted>Feedback Points over time</Muted>
          </div>
          <BarChart
            data={pastWeekIntervalsToShow.map((week) => {
              const weekDetails = feedbackStats?.weekDetails.find(
                (elem) => elem.week === moment(week).week(),
              )
              const meetsChecklist = weekDetails?.done.meets_checklist ?? 0
              const doesNotMeetChecklist =
                weekDetails?.done.does_not_meet_checklist ?? 0
              return {
                label: `W${moment(week).week()}`,
                meetsChecklist,
                doesNotMeetChecklist,
              }
            })}
            bars={['meetsChecklist', 'doesNotMeetChecklist']}
            config={{
              meetsChecklist: {
                label: 'Meets quality threshold',
                color: colors.green['600'],
              },
              doesNotMeetChecklist: {
                label: "Doesn't meet quality threshold",
                color: colors.red['200'],
              },
            }}
            activeIndex={pastWeekIntervalsToShow.length}
            stacked={true}
          />
        </Tile>
        <Tile className="lg:col-span-2 h-[300px]" isLoading={isLoading}>
          <Muted>Company values attributed to others</Muted>
          <div className="flex flex-1 flex-col gap-4 justify-center">
            {sortedCompanyValues.map((value) => (
              <div
                key={value.name}
                className="flex items-center justify-between w-full"
              >
                <span
                  className="w-32 text-sm text-muted-foreground mr-2 truncate font-medium"
                  title={value.name}
                >
                  {value.name}
                </span>
                <div className="flex-1 mx-2">
                  <Progress value={value.score * 20} className="h-4" />
                </div>
                <span className="text-sm font-medium">
                  {value.score.toFixed(1)}
                </span>
              </div>
            ))}
          </div>
        </Tile>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <Tile className="h-[300px]" isLoading={isLoading}>
          <Muted>Values attributed to others over time</Muted>
          <LineChart
            data={pastWeekIntervalsToShow.map((week) => ({
              label: `W${moment(week).week()}`,
              ...lodash.fromPairs(
                feedbackStats.companyValuesKeys.map((key) => [
                  key,
                  average(
                    feedbackStats?.weekDetails
                      .find((elem) => elem.week === moment(week).week())
                      ?.companyValuesScore?.map(
                        (sentimentAnalysis) => sentimentAnalysis[key],
                      ) ?? [],
                  ),
                ]),
              ),
            }))}
            lines={feedbackStats.companyValuesKeys}
            config={lodash.fromPairs(
              feedbackStats.companyValuesKeys.map((value, idx) => [
                value,
                { label: value, color: LINE_CHART_COLORS[idx] },
              ]),
            )}
            domain={[0, 5]}
            legend
            type="monotone"
            initialHiddenLines={feedbackStats.companyValuesKeys.slice(1)}
          />
        </Tile>
        <Tile className="h-[300px]" isLoading={isLoading}>
          <Muted>Quality score over time</Muted>
          <LineChart
            data={pastWeekIntervalsToShow.map((week) => {
              const weekDetails = feedbackStats?.weekDetails.find(
                (elem) => elem.week === moment(week).week(),
              )
              const complianceScore = average(
                weekDetails?.checklistScores.map((elem) => {
                  const total = elem.yes + elem.no + elem.partial
                  return total ? (elem.yes + elem.partial * 0.5) / total : 0
                }) ?? [],
              ).toFixed(2)

              return {
                label: `W${moment(week).week()}`,
                averageCompliance: complianceScore,
              }
            })}
            lines={['averageCompliance']}
            config={{
              averageCompliance: {
                label: 'Average Compliance',
                color: colors.blue['600'],
              },
            }}
            domain={[0, 1]}
            type="monotone"
            referenceLine={{
              y: 0.7,
              stroke: colors.gray['400'],
              strokeDasharray: '3 3',
              label: { value: 'Target: 0.7', position: 'insideTopRight' },
            }}
          />
        </Tile>
      </div>
    </LayoutTile>
  )
}

interface MetricTileProps {
  title: string
  value: string | number
  isLoading: boolean
  valueColor?: string
  tooltipContent: string
  tooltipType?: 'info' | 'warning' | 'error'
}

const MetricTile: React.FC<MetricTileProps> = ({
  title,
  value,
  isLoading,
  valueColor = 'text-primary',
  tooltipContent,
  tooltipType = 'info',
}) => (
  <Tile className="min-h-20" isLoading={isLoading}>
    <div className="flex items-center gap-2">
      <Muted>{title}</Muted>
      <TooltipWithIcon content={tooltipContent} type={tooltipType} />
    </div>
    <H4 className={valueColor}>{value}</H4>
  </Tile>
)

