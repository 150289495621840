import { z } from 'zod'
import { CopyIcon, Star } from 'lucide-react'

import { Modal } from '@/components/common/modal'
import { H4, H5, H6 } from '@/components/ui/typography'
import { Textarea } from '@/components/ui/textarea'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'

import { zodResolver } from '@hookform/resolvers/zod'
import { useFieldArray, useForm } from 'react-hook-form'

import { SetState } from '@/types/globals'
import { TeamMemberPerformanceReview } from '@/types/PerformanceReview'
import { ScrollArea } from './ui/scroll-area'
import { Separator } from './ui/separator'
import { PerformanceReviewCreateSchema } from '@/lib/schema/performance-review.schema'
import { FeedbackSessionModalContent } from './feedback-session-modal'
import { useFetchTeamObjectivesByUserId } from '@/services/api/objective.api'
import { Quarter } from '@/services/utils/dates'
import { Loading } from './ui/loading'
import { Icon } from './ui/icon'
import { useFetchTeamValues } from '@/services/api/team.api'
import { cn } from '@/lib/utils'
import {
  useCreateTeamMemberPerformanceReview,
  useUpdateTeamMemberPerformanceReview,
} from '@/services/api/performanceReview.api'

interface TeamReviewEditModalProps {
  selectedReview: TeamMemberPerformanceReview
  setSelectedReview: SetState<TeamMemberPerformanceReview | undefined>
  selectedQuarter: Quarter
}

type PerformanceReviewSchemaAlias = z.infer<
  typeof PerformanceReviewCreateSchema
>

export const TeamReviewEditModal = ({
  selectedReview,
  setSelectedReview,
  selectedQuarter,
}: TeamReviewEditModalProps) => {
  const { data: userObjectives = [], isFetched: isFetchedObjectives } =
    useFetchTeamObjectivesByUserId(selectedReview.user.id, selectedQuarter)
  const { data: teamValues = [], isFetched: isFetchedTeamValues } =
    useFetchTeamValues()
  const {
    mutate: createTeamMemberPerformanceReview,
    isPending: isLoadingPost,
  } = useCreateTeamMemberPerformanceReview(selectedQuarter)
  const {
    mutate: updateTeamMemberPerformanceReview,
    isPending: isLoadingPatch,
  } = useUpdateTeamMemberPerformanceReview(selectedQuarter)

  function onSubmit(values: PerformanceReviewSchemaAlias) {
    if (selectedReview.review) {
      return updateTeamMemberPerformanceReview(
        {
          id: selectedReview.review.id,
          body: values,
        },
        {
          onSuccess: () => setSelectedReview(undefined),
        },
      )
    }

    createTeamMemberPerformanceReview(values, {
      onSuccess: () => setSelectedReview(undefined),
    })
  }

  return (
    <Modal
      isOpen={!!selectedReview}
      setIsOpen={() => setSelectedReview(undefined)}
      className="w-[90%] max-h-none sm:max-w-[1580px] p-6"
    >
      <div className="h-full flex flex-col">
        <div className="w-full flex gap-4 h-[80vh]">
          <ScrollArea className="flex-1 pr-2">
            <FeedbackSessionModalContent
              user={selectedReview.user}
              dateRange={selectedReview.dateRange}
              canIncludeAll
              isOpen
            />
          </ScrollArea>
          <Separator orientation="vertical" />
          <ScrollArea className="flex-1">
            <div className="flex justify-between items-center mb-4">
              <H4>Edit Performance Review</H4>
              <Button disabled variant="outline" size="sm">
                <CopyIcon className="h-4 w-4 mr-2" />
                Copy from Feedback Session
              </Button>
            </div>
            {!isFetchedObjectives || !isFetchedTeamValues ? (
              <Loading containerClassName="w-full" className="m-auto" />
            ) : (
              <PerformanceReviewForm
                isLoadingMutation={isLoadingPost || isLoadingPatch}
                onSubmit={onSubmit}
                defaultValues={{
                  userId: selectedReview.user.id,
                  quarter: selectedQuarter.quarter,
                  year: selectedQuarter.year,
                  managerReview: selectedReview.review?.managerReview,
                  actionPlan: selectedReview.review?.actionPlan,
                  status: 'submitted',
                  objectives: userObjectives.map((uo) => {
                    const performanceReviewObjective =
                      selectedReview.review?.objectives.find(
                        (o) => o.id === uo.id,
                      )

                    return {
                      id: uo.id,
                      completion: performanceReviewObjective?.completion ?? 0,
                      title: uo.title,
                      comments: performanceReviewObjective?.comments ?? '',
                    }
                  }),
                  values: teamValues.map((value) => {
                    const performanceReviewValue =
                      selectedReview.review?.values.find(
                        (v) => v.id === value.id,
                      )

                    return {
                      id: value.id,
                      rating: performanceReviewValue?.rating ?? 0,
                      label: value.label,
                      comments: performanceReviewValue?.comments ?? '',
                    }
                  }),
                }}
              />
            )}
          </ScrollArea>
        </div>
      </div>
    </Modal>
  )
}

export const PerformanceReviewForm = ({
  defaultValues,
  onSubmit,
  isLoadingMutation,
}: {
  defaultValues?: Partial<PerformanceReviewSchemaAlias>
  onSubmit: (_values: PerformanceReviewSchemaAlias) => void
  isLoadingMutation?: boolean
}) => {
  const form = useForm<PerformanceReviewSchemaAlias>({
    resolver: zodResolver(PerformanceReviewCreateSchema),
    defaultValues: defaultValues ?? {},
  })
  const { fields: objectives } = useFieldArray({
    control: form.control,
    name: 'objectives',
  })
  const { fields: values } = useFieldArray({
    control: form.control,
    name: 'values',
  })

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit, (e) => console.log(e))}
        className="flex flex-col gap-4 p-2"
      >
        <div className="flex flex-col gap-3">
          <H5>Objectives</H5>
          <div className="flex flex-col gap-4">
            {objectives.map((field, index) => (
              <div key={field.id} className="flex flex-col gap-2 px-2">
                <div className="flex justify-between items-center">
                  <H6 className="font-medium">
                    {form.getValues().objectives[index].title}
                  </H6>
                  <FormField
                    {...form.register(`objectives.${index}.completion`)}
                    render={({ field }) => (
                      <FormItem className="w-[100px]">
                        <FormControl>
                          <Input
                            {...field}
                            onChange={(event) =>
                              field.onChange(+event.target.value)
                            }
                            endAdornment={<Icon name="Percent" />}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  {...form.register(`objectives.${index}.comments`)}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Textarea
                          {...field}
                          placeholder="Comments"
                          onChange={(event) =>
                            field.onChange(event.target.value)
                          }
                          rows={3}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <H5>Values</H5>
          <div className="flex flex-col gap-4">
            {values.map((field, index) => (
              <div key={field.id} className="flex flex-col gap-2 px-2">
                <div className="flex justify-between items-center">
                  <H6 className="font-medium">
                    {form.getValues().values[index].label}
                  </H6>
                  <FormField
                    {...form.register(`values.${index}.rating`)}
                    render={({ field }) => (
                      <div className="flex gap-1">
                        {[1, 2, 3, 4, 5].map((star) => (
                          <Star
                            key={`value-${field.name}-star-${star}`}
                            className={cn('h-6 w-6 cursor-pointer', {
                              'text-yellow-400 fill-yellow-400':
                                star <= form.getValues().values[index].rating,
                              'text-gray-300':
                                star > form.getValues().values[index].rating,
                            })}
                            onClick={() => {
                              field.onChange(star)
                            }}
                          />
                        ))}
                      </div>
                    )}
                  />
                </div>
                <FormField
                  {...form.register(`values.${index}.comments`)}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Textarea
                          {...field}
                          placeholder="Comments"
                          onChange={(event) =>
                            field.onChange(event.target.value)
                          }
                          rows={3}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            ))}
          </div>
        </div>
        <FormField
          control={form.control}
          name="managerReview"
          render={({ field }) => (
            <FormItem>
              <H5>Manager&apos;s Review</H5>
              <FormControl>
                <Textarea
                  placeholder="Please write your final review"
                  rows={3}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="actionPlan"
          render={({ field }) => (
            <FormItem>
              <H5>Action Plan</H5>
              <FormControl>
                <Textarea
                  placeholder="Please write your action plan"
                  rows={3}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit" disabled={isLoadingMutation}>
          Save
        </Button>
      </form>
    </Form>
  )
}
