import { z } from 'zod'

const TeamObjectiveValueSchema = z.object({
  value: z.number().nullish(),
  startDate: z.string(),
  weekNumber: z.number().int().positive(),
})

const TeamObjectiveOwnerSchema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  image: z.string(),
})

export const TeamObjectivePatchValueSchema =
  TeamObjectiveValueSchema.partial().extend({
    id: z.string().uuid(),
  })

export const TeamObjectiveCreateSchema = z.object({
  title: z.string().min(2).max(50),
  type: z.enum(['average', 'lastColumn', 'sum']),
  target: z.number(),
  owners: z.array(TeamObjectiveOwnerSchema),
  values: z.array(TeamObjectiveValueSchema),
  year: z.number().int().positive(),
  quarter: z.number().int().min(1).max(4),
})

export const TeamObjectivePatchSchema =
  TeamObjectiveCreateSchema.partial().extend({
    values: z.array(TeamObjectivePatchValueSchema).optional(),
  })
