import { z } from 'zod'
import { useAuth } from '@clerk/clerk-react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import {
  TeamObjectivePatchSchema,
  TeamObjectiveCreateSchema,
  TeamObjectivePatchValueSchema,
} from '@/lib/schema/objective-form.schema'
import { useToast } from '@/hooks/use-toast'
import { createServerErrorToast } from '@/lib/toast'

import { Quarter } from '../utils/dates'
import {
  fetchResolve,
  postResolve,
  patchResolve,
  deleteResolve,
} from './helpers'

import { Objective } from '@/types/Objective'

export const useFetchTeamObjectives = (quarter: Quarter) => {
  const { getToken } = useAuth()
  return useQuery<Objective[]>({
    queryKey: ['useFetchTeamObjectives', quarter],
    queryFn: () =>
      fetchResolve(
        `/team/objective?quarter=${quarter.quarter}&year=${quarter.year}`,
        getToken,
      ),
  })
}

export const useFetchTeamObjectivesByUserId = (
  userId: string,
  quarter: Quarter,
) => {
  const { getToken } = useAuth()
  return useQuery<Objective[]>({
    queryKey: ['useFetchTeamObjectivesByUserId', userId, quarter],
    queryFn: () =>
      fetchResolve(
        `/team/user/${userId}/objective?quarter=${quarter.quarter}&year=${quarter.year}`,
        getToken,
      ),
  })
}

export const useCreateObjective = (selectedQuarter: Quarter) => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async (body: z.infer<typeof TeamObjectiveCreateSchema>) =>
      await postResolve('/team/objective', body, getToken),
    onSuccess: (data: Objective[]) => {
      queryClient.setQueryData(
        ['useFetchTeamObjectives', selectedQuarter],
        (old: Objective[]) => [...old, data],
      )
    },
    onError: (error) => {
      toast(createServerErrorToast(error.message))
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['useFetchTeamObjectivesByUserId'],
      })
    },
  })
}

export const usePatchObjective = (selectedQuarter: Quarter) => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async (props: {
      id: string
      body: z.infer<typeof TeamObjectivePatchSchema>
    }) =>
      await patchResolve(`/team/objective/${props.id}`, props.body, getToken),
    onMutate: async (variables) => {
      await queryClient.cancelQueries({
        queryKey: ['useFetchTeamObjectives', selectedQuarter],
      })
      const previousQuery = queryClient.getQueryData([
        'useFetchTeamObjectives',
        selectedQuarter,
      ])

      // Optimistically update to the new value
      queryClient.setQueryData(
        ['useFetchTeamObjectives', selectedQuarter],
        (old: Objective[]) =>
          old?.map((objective) =>
            objective.id === variables.id
              ? { ...objective, ...variables.body }
              : objective,
          ) ?? [],
      )

      // Return a context object with the snapshotted value
      return { previousQuery }
    },
    onError: (error, _variables, context) => {
      queryClient.setQueryData(
        ['useFetchTeamObjectives'],
        context?.previousQuery ?? [],
      )
      toast(createServerErrorToast(error.message))
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['useFetchTeamObjectives', selectedQuarter],
      })
      queryClient.invalidateQueries({
        queryKey: ['useFetchTeamObjectivesByUserId'],
      })
    },
  })
}

export const useDeleteObjective = (selectedQuarter: Quarter) => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async (id: string) =>
      await deleteResolve(`/team/objective/${id}`, getToken),
    onMutate: async (id) => {
      await queryClient.cancelQueries({
        queryKey: ['useFetchTeamObjectives', selectedQuarter],
      })
      const previousQuery = queryClient.getQueryData([
        'useFetchTeamObjectives',
        selectedQuarter,
      ])

      // Optimistically update to the new value
      queryClient.setQueryData(
        ['useFetchTeamObjectives', selectedQuarter],
        (oldData: Objective[]) =>
          oldData.filter((objective) => objective.id !== id),
      )

      // Return a context object with the snapshotted value
      return { previousQuery }
    },
    onError: (error, _variables, context) => {
      queryClient.setQueryData(
        ['useFetchTeamObjectives'],
        context?.previousQuery ?? [],
      )
      toast(createServerErrorToast(error.message))
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['useFetchTeamObjectives', selectedQuarter],
      })
      queryClient.invalidateQueries({
        queryKey: ['useFetchTeamObjectivesByUserId'],
      })
    },
  })
}

export const usePatchObjectiveValue = (selectedQuarter: Quarter) => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async (props: {
      teamObjectiveId: string
      body: z.infer<typeof TeamObjectivePatchValueSchema>
    }) =>
      await patchResolve(
        `/team/objective/${props.teamObjectiveId}/values`,
        props.body,
        getToken,
      ),
    onMutate: async (variables) => {
      await queryClient.cancelQueries({
        queryKey: ['useFetchTeamObjectives', selectedQuarter],
      })
      const previousQuery = queryClient.getQueryData([
        'useFetchTeamObjectives',
        selectedQuarter,
      ])

      // Optimistically update to the new value
      queryClient.setQueryData(
        ['useFetchTeamObjectives', selectedQuarter],
        (old: Objective[]) =>
          old?.map((objective) =>
            objective.id === variables.teamObjectiveId
              ? {
                  ...objective,
                  values: objective.values.map((v) =>
                    v.id === variables.body.id ? variables.body : v,
                  ),
                }
              : objective,
          ) ?? [],
      )

      // Return a context object with the snapshotted value
      return { previousQuery }
    },
    onError: (error, _variables, context) => {
      queryClient.setQueryData(
        ['useFetchTeamObjectives'],
        context?.previousQuery ?? [],
      )
      toast(createServerErrorToast(error.message))
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['useFetchTeamObjectives', selectedQuarter],
      })
    },
  })
}
