import React from 'react'
import { isNaN } from 'lodash'

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Tooltip, TooltipWithIcon } from '@/components/common/tooltip'
import { H5 } from '@/components/ui/typography'

interface User {
  id: string
  firstName: string
  lastName: string
  image?: string
  objectivesScore: number
  valuesScore: number
}

interface NineBoxGridProps {
  users: User[]
}

const LabelWithTooltip: React.FC<{
  label: string
  percentage: number
  position: 'Low' | 'Mid' | 'High'
}> = ({ label, percentage, position }) => {
  const getPercentageColor = (
    percentage: number,
    position: 'Low' | 'Mid' | 'High',
  ): string => {
    if (isNaN(percentage)) {
      return ''
    } else if (
      (position === 'Low' && percentage >= 10 && percentage <= 30) ||
      (position === 'Mid' && percentage >= 50 && percentage <= 70) ||
      (position === 'High' && percentage >= 10 && percentage <= 30)
    ) {
      return 'text-green-600'
    }
    return 'text-red-600'
  }

  const color = getPercentageColor(percentage, position)
  const isOptimal = color === 'text-green-600'
  const tooltipContent = isOptimal
    ? `Optimal distribution (${position === 'Mid' ? '50-70%' : '10-30%'} for ${position})`
    : `Suboptimal distribution (${position === 'Mid' ? '50-70%' : '10-30%'} for ${position})`

  return (
    <div className="flex items-center">
      <span className={`${color} mr-1`}>
        {label} ({!isNaN(percentage) ? percentage : '--'}%)
      </span>
      <TooltipWithIcon content={tooltipContent} type="info" />
    </div>
  )
}

const NineBoxGrid: React.FC<NineBoxGridProps> = ({ users }) => {
  const getGridPosition = (score: number): number => {
    return score - 1
  }

  const calculatePercentages = () => {
    const objectivesCount = [0, 0, 0]
    const valuesCount = [0, 0, 0]

    users.forEach((user) => {
      const xPos = getGridPosition(user.objectivesScore)
      const yPos = getGridPosition(user.valuesScore)
      objectivesCount[xPos]++
      valuesCount[2 - yPos]++ // Invert Y-axis for correct order
    })

    const totalUsers = users.length
    const objectivesPercentages = objectivesCount.map((count) =>
      Math.round((count / totalUsers) * 100),
    )
    const valuesPercentages = valuesCount.map((count) =>
      Math.round((count / totalUsers) * 100),
    )

    return { objectivesPercentages, valuesPercentages }
  }

  const { objectivesPercentages, valuesPercentages } = calculatePercentages()

  const gridCells = Array(9)
    .fill(null)
    .map((_, index) => {
      const x = index % 3
      const y = 2 - Math.floor(index / 3)
      const cellUsers = users.filter(
        (user) =>
          getGridPosition(user.objectivesScore) === x &&
          getGridPosition(user.valuesScore) === y,
      )

      return (
        <div
          key={index}
          className="min-h-[58px] bg-white rounded-lg flex flex-wrap items-center justify-center p-2 border border-gray-200"
        >
          {cellUsers.map((user) => (
            <Tooltip
              key={user.id}
              content={`${user.firstName} ${user.lastName}, Objectives: ${user.objectivesScore}, Values: ${user.valuesScore}`}
            >
              <Avatar className="h-8 w-8 m-1">
                <AvatarImage
                  src={user.image}
                  alt={`${user.firstName} ${user.lastName}`}
                />
                <AvatarFallback>{`${user.firstName[0]}${user.lastName[0]}`}</AvatarFallback>
              </Avatar>
            </Tooltip>
          ))}
        </div>
      )
    })

  return (
    <div className="w-full max-w-5xl mx-auto p-6">
      <div className="flex flex-col w-full h-full gap-4">
        <div className="flex w-full h-full">
          <H5 className="[writing-mode:vertical-lr] rotate-180 text-center">
            Values
          </H5>
          <div className="hidden lg:flex flex-col justify-between text-sm w-28 py-4 items-end">
            <LabelWithTooltip
              label="High"
              percentage={valuesPercentages[0]}
              position="High"
            />
            <LabelWithTooltip
              label="Mid"
              percentage={valuesPercentages[1]}
              position="Mid"
            />
            <LabelWithTooltip
              label="Low"
              percentage={valuesPercentages[2]}
              position="Low"
            />
          </div>
          <div className="flex-1 flex flex-col ml-4">
            <div className="flex-1 grid grid-cols-3 grid-rows-3 gap-4">
              {gridCells}
            </div>
          </div>
        </div>
        <div className="flex items-end">
          <div className="w-[40px] lg:w-[152px] font-semibold text-base"></div>
          <div className="flex-1 flex flex-col gap-2">
            <div className="hidden lg:flex flex-1 justify-between text-sm px-12">
              <LabelWithTooltip
                label="Low"
                percentage={objectivesPercentages[0]}
                position="Low"
              />
              <LabelWithTooltip
                label="Mid"
                percentage={objectivesPercentages[1]}
                position="Mid"
              />
              <LabelWithTooltip
                label="High"
                percentage={objectivesPercentages[2]}
                position="High"
              />
            </div>
            <H5 className="text-center">Objectives</H5>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NineBoxGrid
