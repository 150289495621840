import { useMemo } from 'react'

import { LayoutTile } from '@/components/layout'
import { H4, Muted, P } from '@/components/ui/typography'
import { Loading } from '@/components/ui/loading'
import { ErrorTile } from '@/components/common/error-tile'
import LineChart from '@/components/common/line-chart'

import { useFetchMyPerformanceReviews } from '@/services/api/performanceReview.api'

import {
  calculateObjectivesGrade,
  calculateValuesGrade,
} from '@/services/utils/performance-reviews'
import { Icon } from '@/components/ui/icon'
import { MeReviewOverviewItem } from '@/components/me-review-overview-item'

const quarterToNumber = (year: number, quarter: number) => year * 100 + quarter
const numberToQuarterLabel = (num: number) => {
  const year = Math.floor(num / 100)
  const quarter = num % 100
  return `Q${quarter} ${year}`
}

export function MyReviewsPage() {
  const {
    data: reviews = [],
    isLoading,
    error,
  } = useFetchMyPerformanceReviews()

  const chartData = useMemo(() => {
    if (!reviews) return { objectives: [] }
    const sortedReviews = [...reviews].sort(
      (a, b) =>
        quarterToNumber(a.year, a.quarter) - quarterToNumber(b.year, b.quarter),
    )
    return {
      objectives: sortedReviews.map((review) => ({
        label: quarterToNumber(review.year, review.quarter),
        objectives: calculateObjectivesGrade(review.objectives),
        values: calculateValuesGrade(review.values),
      })),
    }
  }, [reviews])

  if (error) return <ErrorTile />

  return (
    <LayoutTile>
      <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-4 space-y-4 sm:space-y-0">
        <div>
          <H4 className="font-semibold">My Reviews</H4>
          <P className="text-muted-foreground">
            View your performance reviews and track your progress over time.{' '}
            <a
              href="https://fidforward.canny.io/changelog/performance-reviews-are-now-available"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary hover:underline inline-flex items-center"
            >
              Learn more
              <Icon name="ExternalLink" className="ml-1 w-4 h-4" />
            </a>
          </P>
        </div>
      </div>
      {isLoading ? (
        <div className="flex-1 w-full justify-center items-center flex">
          <Loading />
        </div>
      ) : !reviews.length ? (
        <div className="flex-1 w-full justify-center items-center flex">
          <Muted>No Performance Reviews Available</Muted>
        </div>
      ) : (
        <>
          <div className="mb-8">
            <LineChart
              data={chartData.objectives}
              lines={['objectives', 'values']}
              config={{
                objectives: { label: 'Objectives' },

                values: { label: 'Values' },
              }}
              legend
              domain={[1, 3]}
              type="linear"
              xAxisFormatter={numberToQuarterLabel}
              margin={{ left: 10, right: 10, top: 20, bottom: 0 }}
            />
          </div>
          <div className="mt-4 flex flex-col gap-4">
            {reviews.map((review) => (
              <MeReviewOverviewItem
                key={`${review.year}-Q${review.quarter}`}
                review={review}
              />
            ))}
          </div>
        </>
      )}
    </LayoutTile>
  )
}
