import { DateRange } from 'react-day-picker'
import { CalendarIcon } from 'lucide-react'
import moment from 'moment'
import { useState, useEffect, useRef, useCallback, useMemo } from 'react'

import { Calendar } from '@/components/ui/calendar'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import { Select } from '@/components/common/select'

import { cn } from '@/lib/utils'

import { SetState } from '@/types/globals'

interface DatePickerProps {
  value: DateRange | undefined
  setValue: SetState<DateRange | undefined>
}

const MOMENT_DATE_FORMAT = 'MMM D, YYYY'

export const DatePicker = ({ value, setValue }: DatePickerProps) => {
  const [selectedPreset, setSelectedPreset] = useState('last-3-months')
  const calendarRef = useRef<HTMLDivElement>(null)

  const presets = useMemo(() => [
    {
      label: 'Last month',
      value: 'last-month',
      dateRange: {
        from: moment().subtract(1, 'month').startOf('month').toDate(),
        to: moment().subtract(1, 'month').endOf('month').toDate(),
      },
    },
    {
      label: 'Last 3 months',
      value: 'last-3-months',
      dateRange: {
        from: moment().subtract(3, 'months').startOf('day').toDate(),
        to: moment().endOf('day').toDate(),
      },
    },
    {
      label: 'Last quarter',
      value: 'last-quarter',
      dateRange: {
        from: moment().subtract(1, 'quarter').startOf('quarter').toDate(),
        to: moment().subtract(1, 'quarter').endOf('quarter').toDate(),
      },
    },
    {
      label: 'This quarter',
      value: 'this-quarter',
      dateRange: {
        from: moment().startOf('quarter').toDate(),
        to: moment().endOf('quarter').toDate(),
      },
    },
    {
      label: 'This year',
      value: 'this-year',
      dateRange: {
        from: moment().startOf('year').toDate(),
        to: moment().endOf('year').toDate(),
      },
    },
  ], [])

  const setDefaultPreset = useCallback(() => {
    const defaultPreset = presets.find(preset => preset.value === 'last-3-months')
    if (defaultPreset) {
      setValue(defaultPreset.dateRange)
      setSelectedPreset(defaultPreset.value)
    }
  }, [presets, setValue])

  useEffect(() => {
    setDefaultPreset()
  }, [setDefaultPreset])

  const formatDateRange = (range: DateRange | undefined) => {
    if (!range) return 'Pick a date'
    const { from, to } = range
    if (!from) return 'Pick a date'
    if (!to) return `From ${moment(from).format(MOMENT_DATE_FORMAT)}`
    return `${moment(from).format(MOMENT_DATE_FORMAT)} - ${moment(to).format(MOMENT_DATE_FORMAT)}`
  }

  const handlePresetChange = (value: string) => {
    const preset = presets.find((preset) => preset.value === value)
    if (preset) {
      setValue(preset.dateRange)
      setSelectedPreset(preset.value)
      // Update calendar focus
      if (calendarRef.current) {
        const calendar = calendarRef.current.querySelector('.rdp-month') as HTMLElement
        if (calendar) {
          calendar.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          id="date"
          variant={'outline'}
          className={cn(
            'w-full justify-start text-left font-normal',
            !value && 'text-muted-foreground',
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {selectedPreset ? (
            presets.find(preset => preset.value === selectedPreset)?.label
          ) : (
            formatDateRange(value)
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0" align="start">
        <div className="space-y-2 p-2">
          <Select
            options={presets.map(preset => ({ value: preset.value, label: preset.label }))}
            value={selectedPreset}
            onValueChange={handlePresetChange}
            placeholder="Select a preset"
            className="w-full"
          />
          <div ref={calendarRef}>
            <Calendar
              initialFocus
              mode="range"
              defaultMonth={value?.from}
              selected={value}
              onSelect={(newValue) => {
                setValue(newValue)
                setSelectedPreset('')  // Clear preset when custom date is selected
              }}
              numberOfMonths={2}
            />
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}
