import { useState, useMemo } from 'react'
import moment from 'moment'

import { H4, P, Muted } from '@/components/ui/typography'
import { ExternalLink } from 'lucide-react'
import { Loading } from '@/components/ui/loading'
import { Select } from '@/components/common/select'
import NineBoxGrid from '@/components/ninebox-grid'
import { TeamReviewOverviewItem } from '@/components/team-review-overview-item'
import { TeamReviewEditModal } from '@/components/team-review-edit-modal'
import { ErrorTile } from '@/components/common/error-tile'
import { LayoutTile } from '@/components/layout'

import { useFetchTeamMemberPerformanceReviews } from '@/services/api/performanceReview.api'

import {
  Quarter,
  getQuarterFromDate,
  parseQuarterString,
} from '@/services/utils/dates'
import {
  calculateObjectivesGrade,
  calculateValuesGrade,
} from '@/services/utils/performance-reviews'

import { TeamMemberPerformanceReview } from '@/types/PerformanceReview'

export const MyTeamReviewsPage = () => {
  const [selectedQuarter, setSelectedQuarter] = useState<{
    label: string
    value: Quarter
  }>(getQuarterFromDate(moment().add({ quarter: -1 }).format()))
  const [selectedTeamReview, setSelectedTeamReview] = useState<
    TeamMemberPerformanceReview | undefined
  >(undefined)
  const [employeeScope, setEmployeeScope] = useState<'all' | 'direct'>('direct')

  const {
    data: teamReviews = [],
    isLoading,
    error,
  } = useFetchTeamMemberPerformanceReviews(
    selectedQuarter.value,
    employeeScope === 'all',
  )

  const nineBoxUsers = useMemo(() => {
    return teamReviews
      .filter((tr) => tr.review?.hidden === false)
      .map((tr) => ({
        id: tr.user.id,
        firstName: tr.user.firstName,
        lastName: tr.user.lastName,
        image: tr.user.image,
        objectivesScore: calculateObjectivesGrade(tr.review?.objectives ?? []),
        valuesScore: calculateValuesGrade(tr.review?.values ?? []),
      }))
  }, [teamReviews])

  if (error) {
    return <ErrorTile />
  }

  return (
    <LayoutTile>
      <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-4 space-y-4 sm:space-y-0">
        <div>
          <H4 className="font-semibold">Your Team&apos;s Reviews</H4>
          <P className="text-muted-foreground">
            View and manage performance reviews for your team members.{' '}
            <a
              href="https://fidforward.canny.io/changelog/performance-reviews-are-now-available"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary hover:underline inline-flex items-center"
            >
              Learn more
              <ExternalLink size={16} className="ml-1" />
            </a>
          </P>
        </div>
        <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-4">
          <Select
            options={[
              { value: 'direct', label: 'Only direct reports' },
              { value: 'all', label: 'All employees under me' },
            ]}
            value={employeeScope}
            onValueChange={(value) => setEmployeeScope(value)}
          />
          <Select
            className="w-[130px]"
            options={[
              { value: '2024 Q1', label: '2024 Q1' },
              { value: '2024 Q2', label: '2024 Q2' },
              { value: '2024 Q3', label: '2024 Q3' },
              { value: '2024 Q4', label: '2024 Q4' },
            ]}
            value={selectedQuarter.label}
            onValueChange={(value) =>
              setSelectedQuarter(parseQuarterString(value))
            }
          />
        </div>
      </div>
      {isLoading ? (
        <div className="flex-1 w-full justify-center items-center flex">
          <Loading />
        </div>
      ) : (
        <div>
          <div className="w-full max-w-3xl mx-auto">
            <NineBoxGrid users={nineBoxUsers} />
          </div>
          <div className="mt-4 flex flex-col gap-4">
            {teamReviews.length === 0 ? (
              <Muted className="my-16">
                No Team Reviews Available for this Quarter
              </Muted>
            ) : (
              teamReviews.map((review: TeamMemberPerformanceReview) => (
                <TeamReviewOverviewItem
                  key={`team-review-${review.user.id}`}
                  data={review}
                  handleEditClick={() => setSelectedTeamReview(review)}
                  selectedQuarter={selectedQuarter.value}
                />
              ))
            )}
            {!!selectedTeamReview && (
              <TeamReviewEditModal
                selectedReview={selectedTeamReview}
                setSelectedReview={setSelectedTeamReview}
                selectedQuarter={selectedQuarter.value}
              />
            )}
          </div>
        </div>
      )}
    </LayoutTile>
  )
}
