import { useAuth } from '@clerk/clerk-react'
import { useQuery } from '@tanstack/react-query'

import { addQueryParams, fetchResolve } from './helpers'

import { UserProfile } from '@/types/UserProfile'

export const useFetchTeamMembers = (options?: {
  onlyDirectReports?: boolean
}) => {
  const { getToken } = useAuth()
  return useQuery<UserProfile[]>({
    queryKey: ['useFetchTeamMembers', options],
    queryFn: () =>
      fetchResolve(
        addQueryParams(`/team/user`, {
          onlyDirectReports: options?.onlyDirectReports,
        }),
        getToken,
      ),
    initialData: [],
  })
}

export const useFetchTeamValues = () => {
  const { getToken } = useAuth()
  return useQuery<{ label: string; id: string }[]>({
    queryKey: ['useFetchTeamValues'],
    queryFn: () => fetchResolve(`/team/values`, getToken),
    initialData: [],
  })
}
