import { useState } from 'react'
import moment from 'moment'
import { DateRange } from 'react-day-picker'

import { Button } from '@/components/ui/button'
import { AvatarWithFallback } from '@/components/ui/avatar'
import { H4, P, Muted, H5 } from '@/components/ui/typography'
import { Loading } from '@/components/ui/loading'
import { DatePicker } from '@/components/common/date-picker'
import { FeedbackSessionModal } from '@/components/feedback-session-modal'
import { TooltipWithIcon } from '@/components/common/tooltip'
import { LayoutTile } from '@/components/layout'

import { useFetchFeedbackSessions } from '@/services/api/feedbackSessions.api'

import { FeedbackSessionOverview } from '@/types/FeedbackSessions'
import { ExternalLink } from 'lucide-react'
import {
  getUserAvatarFallback,
  getUserDisplayName,
} from '@/services/utils/formatters'

interface FeedbackSessionOverviewProps {
  data: FeedbackSessionOverview
}

const FeedbackSessionOverviewItem = ({
  data,
}: FeedbackSessionOverviewProps) => {
  const { user } = data
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenClick = async () => {
    setIsModalOpen(true)
  }

  return (
    <div className="flex flex-col sm:flex-row sm:items-center justify-between py-4 space-y-4 sm:space-y-0 border-b">
      <div className="flex items-center space-x-4">
        <AvatarWithFallback
          className="h-12 w-12 border-2 border-primary"
          image={user.image}
          fallback={getUserAvatarFallback(data.user)}
        />
        <div>
          <H5>{getUserDisplayName(data.user)}</H5>
          <div className="flex items-center pt-1 text-sm">
            <P className="mr-1">Feedback points:</P>
            <span
              className={
                data.feedbackPointsFromMe < 3
                  ? 'text-red-300'
                  : 'text-green-500'
              }
            >
              {data.feedbackPointsFromMe} from you
              {data.canIncludeAll && (
                <>, {data.feedbackPointsFromOthers} from others</>
              )}
            </span>
            <span className="ml-1">
              <TooltipWithIcon
                content="We recommend at least 3 feedback points for an optimal report."
                type="info"
              />
            </span>
          </div>
        </div>
      </div>
      <Button
        onClick={handleOpenClick}
        className="w-full sm:w-auto"
        disabled={!(data.feedbackPointsFromOthers + data.feedbackPointsFromMe)}
      >
        Open
      </Button>
      <FeedbackSessionModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        feedbackSessionOverview={data}
      />
    </div>
  )
}

export const FeedbackSessionsPage = () => {
  const [selectedDateRange, setSelectedDateRange] = useState<
    DateRange | undefined
  >({
    from: moment().startOf('quarter').toDate(),
    to: new Date(),
  })

  const { data: feedbackSessions = [], isLoading } = useFetchFeedbackSessions(
    selectedDateRange
      ? {
          from: moment(selectedDateRange.from).valueOf(),
          to: moment(selectedDateRange.to).valueOf(),
        }
      : undefined,
  )

  return (
    <LayoutTile>
      <div>
        <H4 className="font-semibold">Your Feedback Sessions</H4>
        <div className="flex flex-col sm:flex-row sm:justify-between mt-2 gap-4">
          <P className="text-muted-foreground">
            Generate a report to be used in a feedback session (e.g.,
            performance review). If you are the manager/director of the person,
            you can also see the feedback from other colleagues.{' '}
            <a
              href="https://fidforward.canny.io/changelog/feedback-sessions-is-now-generally-available"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary hover:underline inline-flex items-center"
            >
              Learn more
              <ExternalLink size={16} className="ml-1" />
            </a>
          </P>
          <div className="flex items-center gap-4">
            <DatePicker
              value={selectedDateRange}
              setValue={setSelectedDateRange}
            />
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="flex-1 w-full justify-center items-center flex">
          <Loading />
        </div>
      ) : !feedbackSessions.length ? (
        <div className="flex-1 w-full justify-center items-center flex">
          <Muted>No Feedback Sessions Available</Muted>
        </div>
      ) : (
        <div>
          {feedbackSessions.map((fs) => (
            <FeedbackSessionOverviewItem key={`fs-${fs.user.id}`} data={fs} />
          ))}
        </div>
      )}
    </LayoutTile>
  )
}
